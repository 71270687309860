import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import { useState, useEffect, useRef  } from "react";
import HeroCanvas from "./heroCanvas.js"

const colors = [
  "#FF6F61",
  "#6B5B95",
  "#88B04B",
  "#FFA500",
  "#009688",
  "#FFB6C1",
  "#40E0D0",
  "#FFD700",
  "#C71585",
  "#4682B4",
  "#DC143C",
  "#FF4500",
  "#7FFF00",
  "#6495ED",
  "#8A2BE2",
  "#FF6347",
  "#00FA9A",
  "#00CED1",
  "#FF69B4",
  "#FFDAB9",
];

const Head1 = styled(Button)(({ theme }) => ({
  textTransform: "none",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  fontSize: "4rem",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "none",
    borderColor: "none",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "unset",
    boxShadow: "none",
  },
}));


const Head2 = styled(Button)(({ theme }) => ({
  textTransform: "none",
  ...theme.typography.body2,
  padding: theme.spacing(0.8),
  textAlign: "center",
  fontSize: "1.4rem",
  borderColor: "F9F6EE",
  border: "3px solid", 
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "none",
    borderColor: "none",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "unset",
    boxShadow: "none",
  },
}));

const StyledBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#121212",
  overflow: "hidden",
  position: "relative",
  paddingBottom: "20vh", // Adds more space below, pushing text up
});

function Header({ aboutRef }) {
  const [color1, setColor1] = useState("#F9F6EE");
  const [color2, setColor2] = useState("#F9F6EE");
  const [color3, setColor3] = useState("#F9F6EE");
  const [delay, setDelay] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => setDelay(0), 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleColorChange = (setColor) => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    setColor(randomColor);
  };

  // Scroll to the "work" section on button click
  const scrollToAboutSection = () => {
    if (aboutRef.current) {
      const appBarHeight = 90; // Adjust based on your AppBar's height
      const targetPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY - appBarHeight;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <StyledBox>
      <HeroCanvas/>
      <Stack spacing={4} alignItems="center" sx={{ zIndex: 1, position: 'relative' }}>
        <motion.div
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 3, type: "spring", stiffness: 100 }}
          whileHover={{ scale: 1.3 }}
        >
          <Head1
            sx={{ padding: 0, color: color1 }}
            onClick={() => handleColorChange(setColor1)}
          >
            Hi there👋
          </Head1>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.3 }}
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, y: 0, opacity: 1 }}
          transition={{
            duration: 10,
            type: "spring",
            stiffness: 100,
          }}
        >
          <Head1
            sx={{ padding: 0, color: color2 }}
            onClick={() => handleColorChange(setColor2)}
          >
            My name's Aidan
          </Head1>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          initial={{ y: 150, opacity: 0 }}
          animate={{ x: 0, y: 0, opacity: 1 }}
          transition={{
            duration: 10,
            type: "spring",
            stiffness: 100,
            delay: delay,
          }}
        >
          <Head2 onClick={scrollToAboutSection} sx={{ color: color3 }}>View my work ↓</Head2>
        </motion.div>
      </Stack>
    </StyledBox>
  );
}

export default Header;