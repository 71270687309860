import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header.js';
import TopLinks from './components/TopLinks.js';
import AboutMe from './components/AboutMe.js';
import Projects from './components/Projects.js';
import { useRef } from 'react';
import Footer from './components/Footer.js';
import SideBar from './components/SideBar.js';
import { Box } from '@mui/material'; // Import Box for layout

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function App() {
  const aboutRef = useRef(null);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'row', minHeight: '100vh' }}>
        {/* Main Content Area */}
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <TopLinks />
          <Header aboutRef={aboutRef} />
          <AboutMe ref={aboutRef} />
          <Projects />
          <Footer />
        </Box>
        <Box sx={{
        // Dark gray background color
            width: '75px',
            position: 'fixed',
            right: 20,
            top: '50%', // Center vertically
            transform: 'translateY(-61%)', // Adjust to truly center
            height: '80vh', // Allow for dynamic height
            zIndex: 10
          }}>
          <SideBar></SideBar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}