import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

export default function DenseAppBar() {
  return (
    <Box sx={{ flexGrow: 1, zIndex: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#121212", boxShadow: "none", pb: 2, zIndex: 5 }}
        elevation={0} // This removes any AppBar shadow
      >
        <Toolbar
          variant="dense"
          sx={{ justifyContent: "center", backgroundColor: "#121212",  minHeight: 80 }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/aidan-o-brien-393486274/",
                "_blank"
              )
            }
          >
            <LinkedInIcon fontSize="large"/>
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() =>
                window.open(
                  "https://github.com/aidanobrien5599",
                  "_blank"
                )
              }
          >
            <GitHubIcon fontSize="large" />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: -4}}
            onClick={() =>
                window.open(
                  "mailto:aidanobrien5599@gmail.com",
                  "_blank"
                )
              }
          >
            <EmailIcon fontSize="large"/>
          </IconButton >
        </Toolbar>
      </AppBar>
    </Box>
  );
}
