import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import Neural from '../images/Neural.gif';

export default function GPTNoteCard() {
  const [expanded, setExpanded] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  return (
    <motion.div
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      style={{ display: 'inline-block' }}
    >
      <Card sx={{ maxWidth: 500, minWidth: 440, minHeight: 470, borderRadius: '30px', position: 'relative' }}>
        {/* Centered GIF */}
        <Box sx={{ pt: 8 }}>
          <motion.div
            animate={{ opacity: hovered ? 0 : 1 }}
            transition={{ duration: 0.5 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={Neural}
              alt=""
              sx={{
                height: 280,
                width: 250,
                objectFit: 'cover',
                margin: 'auto',
                pt: 2,
                borderRadius: '30px',
              }}
            />
          </motion.div>
        </Box>
        
        <CardContent sx={{ pt: 1, pb: 1, px: 4 }}>
          <motion.div
            animate={{ opacity: hovered ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              zIndex: 2,
              color: '#000',
              width: "65%", // Reduces padding around the text to expand it closer to card edges
            }}
          >
            <Typography variant="body1" color="text.primary" sx={{ textAlign: "justify", fontSize: "0.9rem",   w: "430"}}>
              Have you ever been in class and not been able to keep up with the lecture pace while taking notes? GPTNote offers a solution to this problem with LLM langauge completion on top of an advanced WYSIWYG word processor. GPTNote is built with Google Firebase authentication, and Firebase's Firestore to store user information in real-time. This project was my first time ever working with cloud web services. Use autoAI switch the toggle the autocomplete feature on and off, and click the tab or alt button on your computer to confirm the suggestion.
            </Typography>
          </motion.div>
        </CardContent>
        
        <CardActions disableSpacing>
          {/* Actions go here */}
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* Expanded content goes here */}
          </CardContent>
        </Collapse>
        
        {/* Bottom-left positioned title */}
        <motion.div
          animate={{ y: hovered ? -380 : 0 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 3,
            width: '100%',
            padding: '16px',
          }}
        >
          <CardHeader
            title="AI Notes App 🧠✏️"
            sx={{
              pl: 2,
              pb: 1,
              "& .MuiCardHeader-title": {
                fontWeight: 'bold',
              },
            }}
          />
        </motion.div>

        {/* Button to appear in place of the title */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: hovered ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '16px',
            display: 'flex',
            justifyContent: 'left',
            zIndex: 3,
            borderRadius: 20,
          }}
        >
          <Button variant="contained" 
           onClick={() => {
            window.open("https://notegpt-87917.web.app/login", "_blank", "noopener,noreferrer"); // replace with your Flask route
          }}
          sx={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            fontFamily: "'Fredoka One', sans-serif", // Add a playful font
            textTransform: "none",
            borderRadius: 20,
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}>
            Try Out Here
          </Button>
        </motion.div>
      </Card>
    </motion.div>
  );
}