import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FamilyImage from '../images/Family.png'; 
import BanffImage from '../images/Banff.png';
import LinkedInImage from '../images/LinkedIn.PNG';
import SurfingImage from '../images/Surfing.JPG';
import YankeeImage from '../images/Yankee.JPG';
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import DownloadIcon from '@mui/icons-material/Download';
// Import the download icon

const AboutMe = React.forwardRef((props, ref) => {
  const [index, setIndex] = useState(0);
  const [rotate, setRotate] = useState(false);
  const images = [BanffImage, FamilyImage, SurfingImage, YankeeImage, LinkedInImage];
  const captions = [
    "Taking it in at Banff National Park 🖼️🗻🌲", 
    "Me and the family! 👪", 
    "Pre morning surf shesh 🌊🏄", 
    "Yankee game with some friends⚾", 
    "Professional LinkedIn Shoot 💼🖊️"
  ];

  const handleImageClick = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleRotateClick = () => {
    setRotate((prev) => !prev);
  };

  const handleResumeDownload = () => {
    const link = document.createElement("a");
    link.href = "/OBRIEN_AIDAN_RESUME.pdf"; // Ensure this path is correct
    link.download = "Aidan_OBRIEN_Resume.pdf"; // Suggested name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const Head6 = styled(Button)(({ theme }) => ({
    textTransform: "none",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    fontSize: "1.8rem",
    fontWeight: "bold",
    fontFamily: "'Poppins', sans-serif",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#121212",
      borderColor: "none",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "unset",
      boxShadow: "none",
    },
  }));

  return (
    <Box ref={ref} sx={{ mr: 4, ml: 4 }}>
      {/* Main Header */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
        <Divider sx={{ width: "10%", borderWidth: "2px" }} />
      </Box>
    
      {/* Rotating Subheader */}
      <Box sx={{ display: "flex", justifyContent: "center", width: "50%", margin: "0 auto", mt: 4, mb: 2 }}>
        <motion.div
          animate={{ rotate: rotate ? 360 : 0 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
          onClick={handleRotateClick}
        >
          <Head6 variant="h6">
            About Me
          </Head6>
        </motion.div>
      </Box>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={4} justifyContent="center" sx={{ mt: 4 }}>
        {/* Box for Images and Caption */}
        <Box sx={{ width: "45vh", textAlign: "center" }}>
          <motion.div whileHover={{ scale: 1.02 }}>
            <Button
              onClick={handleImageClick}
              aria-label="Next Image"
              sx={{
                width: "100%", 
                height: "360px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${images[index]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></Button>
          </motion.div>
          {/* Caption Box */}
          <Typography variant="caption" sx={{ display: "block", mt: 1 }}>
            {captions[index]}
          </Typography>
        </Box>

        {/* Box for Paragraph */}
        <Box
          sx={{
            width: "45vh",
            height: "45vh",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // Shift text slightly up
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ height: "100%", overflowY: "clip", mb: 2 }}>
            I'm currently a computer science major at the University of Wisconsin-Madison interested in software development, big data, and machine learning. Currently, I am working at Informatics Skunkworks on a generative AI research team, as we are trying to efficiently develop materials from unstable datasets using diffusion models. This summer, I was one of the initial full-stack engineers at Collectwise, an AI debt collection start-up part of YC's Fall '24 class. Outside of work and programming, I am a very active person; I like lifting, snowboarding, hiking, and recently started jiu-jitsu. Growing up on the Jersey Shore,
            I enjoy surfing, pizza, and New York sports. When school is not in session, I also love to read, play chess, and cook.
          </Typography>
          <Button 
            variant="contained" 
            onClick={handleResumeDownload}// Suggested name for the downloaded file
            sx={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "bold",
              fontFamily: "'Fredoka One', sans-serif",
              textTransform: "none",
              borderRadius: 20,
              mt: 2, // Space between text and button
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
            }}
          >
            Resume 
            <DownloadIcon  /> {/* Adds spacing between text and icon */}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
});

export default AboutMe;