import React, { useState } from 'react';
import CalculatorCard from './CalculatorCard.js';
import GPTNoteCard from './GPTNoteCard.js';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { motion } from "framer-motion";
import Divider from '@mui/material/Divider';
import TeekoCard from './TeekoCard.js';
import LoginCard from './LoginCard.js';

// Styled component for the animated text
const AnimatedText = styled(motion.span)(({ theme }) => ({
  textTransform: "none",
  ...theme.typography.body2,
  fontSize: "1.8rem",
  fontWeight: "bold",
  fontFamily: "'Poppins', sans-serif",
  cursor: "pointer", // Change cursor to pointer for better UX
  "&:hover": {
    backgroundColor: "#121212", // Match Head6 hover effect
    borderColor: "none",
    boxShadow: "none",
  },
}));

function Projects() {
  const [isClicked, setIsClicked] = useState(false); // State to manage click
  const text = "Projects";

  const handleClick = () => {
    setIsClicked(true);
    // Reset the state after a short delay to allow for the animation to finish
    setTimeout(() => setIsClicked(false), 1000);
  };

  return (
    <Box sx={{ m: 4, p: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 0, mb: 2 }}>
        <Divider sx={{ width: "10%", borderWidth: "2px" }} />
      </Box>

      {/* Header with spacing above and below */}
      <Box sx={{ display: "flex", justifyContent: "center", width: "50%", margin: "0 auto", mt: 4, mb: 2 }}>
        <motion.div onClick={handleClick}>
          {text.split('').map((char, index) => (
            <AnimatedText
              key={index}
              initial={{ y: 0, opacity: 1 }}
              animate={isClicked ? { y: 20, opacity: 0 } : { y: 0, opacity: 1 }} // Move down and fade out
              transition={{
                duration: 0.5,
                delay: index * 0.05, // Delay each letter for a staggered effect
              }}
            >
              {char}
            </AnimatedText>
          ))}
        </motion.div>
      </Box>


      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        
        <Stack
          direction="row"
          spacing={4} // Spacing between cards
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 2, // Padding around stack
          }}
        >
          <TeekoCard />
          <GPTNoteCard />
        </Stack>
      </Box>
      {/* Centered Stack with Spacing */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        
        <Stack
          direction="row"
          spacing={4} // Spacing between cards
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 2, // Padding around stack
          }}
        >
          <LoginCard />
          <CalculatorCard />
        </Stack>
      </Box>
    </Box>
  );
}

export default Projects;
