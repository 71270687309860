import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  const version = "1.3, by Aidan O'Brien"; // Update this to your current version
  const funnyComment = "Constantly in Improvement"; // Your humorous comment

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#121212',
        color: 'white',
        py: 2.5,
        textAlign: 'center',
        mt: 'auto', // Push it to the bottom
      }}
    >
      <Typography variant="body2" sx={{ color: 'gray', fontSize: '0.7rem' }}>
        Version: {version}
      </Typography>
      <Typography variant="body2" sx={{ color: 'gray', fontSize: '0.7rem' }}>
        {funnyComment}
      </Typography>
    </Box>
  );
}

export default Footer;