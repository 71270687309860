import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Circles from '../images/Circles.gif';
import Box from '@mui/material/Box'
import { motion } from "framer-motion";
import Button from '@mui/material/Button'

export default function Calculator() {
  const [expanded, setExpanded] = React.useState(false);

  const [hovered, setHovered] = React.useState(false);

  return (
    <motion.div
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      style={{ display: 'inline-block' }}
    >

    <Card sx={{ maxWidth: 500, minWidth: 440, minHeight: 470, borderRadius: '30px', position: 'relative' }}>
      {/* Centered GIF */}
      <Box sx={{pt:8}}>
      <motion.div
            animate={{ opacity: hovered ? 0 : 1 }}
            transition={{ duration: 0.5 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={Circles}
              alt=""
              sx={{
                height: 280,
                width: 250,
                objectFit: 'cover',
                margin: 'auto',
                pt: 2,
                borderRadius: '30px',
              }}
            />
          </motion.div>
      </Box>
      
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <motion.div
            animate={{ opacity: hovered ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              zIndex: 2,
              color: '#000',
              width: "65%",
            }}
          >
            <Typography variant="body1" color="text.primary">
              For my final project for AP Computer Science Principle way back to my sophomore year of high school, I built a fully-featured financial calculator application using Python, Numpy, and Matplotlib. As I first began to learn web development this past year, I first came back to this program and connected it to a Flask template, Jinja frontend, and SQL database. The program takes several inputs and outputs a graph and investment outcomes year-by-year. 
            </Typography>
          </motion.div>
      </CardContent>
      
      <CardActions disableSpacing>
        {/* Actions go here */}
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* Expanded content goes here */}
        </CardContent>
      </Collapse>
      
      {/* Bottom-left positioned title */}
      <motion.div
          animate={{ y: hovered ? -380 : 0 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 3,
            width: '100%',
            padding: '16px',
          }}
        >
          <CardHeader
            title="My First Project 🧮📈"
            sx={{
              pl: 2,
              pb: 1,
              "& .MuiCardHeader-title": {
                fontWeight: 'bold',
              },
            }}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: hovered ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '16px',
            display: 'flex',
            justifyContent: 'left',
            zIndex: 3,
            borderRadius: 20,
          }}
        >

          
          <Button variant="contained" 
          onClick={() => {
            window.open("https://deploymentcalc.vercel.app/", "_blank", "noopener,noreferrer");
          }}
          sx={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            fontFamily: "'Fredoka One', sans-serif", // Add a playful font
            textTransform: "none",
            borderRadius: 20,
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}>
            Try Out Here
          </Button>
        </motion.div>
    </Card>
    </motion.div>
  );
}