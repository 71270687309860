import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function SideBar() {
  const [sidebarText, setSidebarText] = useState('Welcome to My Page!');

  // Update the text based on scroll position
  useEffect(() => {
    const scrollHandler = () => {
      const scrollPosition = window.scrollY;
      
      if (scrollPosition < 600) {
        setSidebarText('Welcome to My Page!');
      } else if (scrollPosition >= 600 && scrollPosition < 1200) {
        setSidebarText('A Little About Me...');
      } else if (scrollPosition >= 1200 && scrollPosition < 1400) {
        setSidebarText('Here Are My Projects!');
      } else {
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', scrollHandler);
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <Box
      sx={{ 
        backgroundColor: '#1C1C1C', 
        padding: 2, 
        position: 'relative', 
        height: '100%', 
        borderRadius: 3,
        display: 'flex', 
        alignItems: 'top', 
        justifyContent: 'flex-start', 
        flexDirection: 'column'
      }}
    >
      <Typography 
        sx={{
          writingMode: 'vertical-lr', 
          color: '#FFFFFF', 
          fontWeight: 'bold', 
          fontSize: '28px', 
          textAlign: 'center',
          mb: 2,
          pt: 4,
        }}
      >
        Homepage
      </Typography>

      {/* Updated Typography with less intense glow and unique effect */}
      <Typography 
        sx={{
          writingMode: 'vertical-lr', 
          textAlign: 'center',
          fontFamily:  '"Nunito", sans-serif', 
          fontWeight: 'bold', 
          fontSize: '24px', 
          pt: 6,
          background: 'linear-gradient(90deg, #00FF00, #00FFFF)', // Gradient effect
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          textShadow: '0 0 5px rgba(0, 255, 255, 0.5)', // Less intense glow
        }}
      >
        {sidebarText}
      </Typography>
    </Box>
  );
}

export default SideBar;