//SOURCE /// Code adapted from bscottnz/portfolio-site on Github


import { useEffect, useRef } from "react";

const HeroCanvas = () => {
  const canvasRef = useRef(null);
  const mousePosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const colorDot = [
      'rgb(81, 162, 233)',
      'rgb(81, 162, 233)',
      'rgb(81, 162, 233)',
      'rgb(81, 162, 233)',
      'rgb(255, 77, 90)',
    ]; // 80% of dots are blue, 20% pink

    let dots = initializeDots();

    function initializeDots() {
      const windowSize = window.innerWidth;
      if (windowSize > 1600) return { nb: 600, distance: 70, d_radius: 300, array: [] };
      if (windowSize > 1300) return { nb: 575, distance: 60, d_radius: 280, array: [] };
      if (windowSize > 1100) return { nb: 500, distance: 55, d_radius: 250, array: [] };
      if (windowSize > 800) return { nb: 300, distance: 0, d_radius: 0, array: [] };
      if (windowSize > 600) return { nb: 200, distance: 0, d_radius: 0, array: [] };
      return { nb: 100, distance: 0, d_radius: 0, array: [] };
    }

    function Dot() {
      this.x = Math.random() * canvas.width;
      this.y = Math.random() * canvas.height;
      this.vx = -0.5 + Math.random();
      this.vy = -0.5 + Math.random();
      this.radius = Math.random() * 1.5;
      this.colour = colorDot[Math.floor(Math.random() * colorDot.length)];
    }

    Dot.prototype = {
      create: function () {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        const dotDistance = Math.hypot(this.x - mousePosition.current.x, this.y - mousePosition.current.y);
        const distanceRatio = dotDistance / (window.innerWidth / 1.7);
        ctx.fillStyle = this.colour.slice(0, -1) + `,${1 - distanceRatio})`;
        ctx.fill();
      },
      animate: function () {
        if (this.y < 0 || this.y > canvas.height) this.vy = -this.vy;
        if (this.x < 0 || this.x > canvas.width) this.vx = -this.vx;
        this.x += this.vx;
        this.y += this.vy;
      },
      line: function (otherDot) {
        const dotDistance = Math.hypot(this.x - otherDot.x, this.y - otherDot.y);
        if (dotDistance < dots.distance) {
          // Only connect lines if within d_radius of the mouse
          if (
            Math.hypot(this.x - mousePosition.current.x, this.y - mousePosition.current.y) < dots.d_radius &&
            Math.hypot(otherDot.x - mousePosition.current.x, otherDot.y - mousePosition.current.y) < dots.d_radius
          ) {
            ctx.beginPath();
            ctx.moveTo(this.x, this.y);
            ctx.lineTo(otherDot.x, otherDot.y);
            const distanceRatio = Math.hypot(this.x - mousePosition.current.x, this.y - mousePosition.current.y) / dots.d_radius;
            ctx.strokeStyle = `rgb(81, 162, 233, ${Math.max(1 - distanceRatio, 0.3)})`;
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
    };

    function createDots() {
      ctx.fillStyle = '#121212';
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      dots.array.forEach((dot, index) => {
        dot.create();
        dot.animate();
        dots.array.slice(index + 1).forEach(otherDot => dot.line(otherDot));
      });
      requestAnimationFrame(createDots);
    }

    function handleResize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      dots = initializeDots();
      dots.array = Array.from({ length: dots.nb }, () => new Dot());
    }

    function handleMouseMove(event) {
      mousePosition.current.x = event.pageX;
      mousePosition.current.y = event.pageY;
      dots.array[0].x = event.pageX; // First dot follows the mouse
      dots.array[0].y = event.pageY;
    }

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    dots.array = Array.from({ length: dots.nb }, () => new Dot());
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    createDots();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <canvas ref={canvasRef} style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      pointerEvents: 'none',
    }} />
  );
};

export default HeroCanvas;